import { bake } from '@sonos-inc/www-bakery'
import { Box, ContentPage } from '@sonos-inc/www-components'
import { UserContentProvider } from '@sonos-inc/www-contexts'
// import { fetchHeaderSubcategories } from '@sonos-inc/www-lib'
// import { resolveLocale } from '@sonos-inc/www-routing-helpers'
// import { pageBySlug } from '@sonos-inc/www-queries'
// import { fetchPageBySlug, fetchPageData, getClient } from '@sonos-inc/www-server'
import { GetStaticProps, InferGetStaticPropsType } from 'next'
import Head from 'next/head'
import React from 'react'

function Custom404({
  page,
  title,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  React.useEffect(() => {
    let url = '/sorry-page-not-found'
    if (process.env.NEXT_PUBLIC_SONOS_ENV_URL)
      url = `${process.env.NEXT_PUBLIC_SONOS_ENV_URL}${url}`
    window.location.assign(url)
  }, [])

  return (
    <Box {...bake()}>
      <Head>
        {title && <title>{title}</title>}
        <meta name="robots" content="none" />{' '}
        {/* Don't index error pages in the search engines */}
        <base target="_PARENT" />
      </Head>

      <UserContentProvider pids={[]}>
        <ContentPage id="main" {...page} />
      </UserContentProvider>
    </Box>
  )
}

export default Custom404

export const getStaticProps: GetStaticProps = async ({
  preview = false,
  locale: nextLocale,
}) => {
  // Uncomment this when 404 from nextweb is expected to be used

  // const locale = resolveLocale(nextLocale)
  // const client = getClient(preview)
  // const slug = 'page-not-found'

  // const [
  //   page,
  //   { currentLocale, forms, microcopy, svgs, header, footer, ribbon },
  //   navHeaderSubCategoryData,
  // ] = await Promise.all([
  //   fetchPageBySlug(locale, slug, null),
  //   fetchPageData(
  //     locale,
  //     [
  //       'icon-left-chevron-arrow.svg',
  //       'icon-left-chevron-arrow-mobile.svg',
  //       'icon-right-chevron-arrow.svg',
  //       'icon-right-chevron-arrow-mobile.svg',
  //       'icon-external-link.svg',
  //       'icon-close.svg',
  //       'icon-search.svg',
  //       'icon-loader.svg',
  //       'icon-compare.svg',
  //     ],
  //     ['www'],
  //     ['search'],
  //     slug
  //   ),
  //   fetchHeaderSubcategories(locale),
  // ])

  // return {
  //   props: {
  //     preview,
  //     slug,
  //     locale,
  //     page,
  //     currentLocale,
  //     forms,
  //     microcopy,
  //     svgs,
  //     header,
  //     footer,
  //     ribbon,
  //     navHeaderSubCategoryData,
  //   },
  //   revalidate: Number(
  //     navHeaderSubCategoryData
  //       ? process.env.NEXT_PAGE_REVALIDATE_SECONDS || 1800
  //       : 60
  //   ),
  // }
  return {
    props: {},
  }
}
